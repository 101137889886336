import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { whitelabel_id } from "../../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../../constants/endpoints"
import apiCall from "../../../utils/apiCall"
import Swal from "sweetalert2"
import toastr from 'toastr'
import { Modal, Input } from "antd"
const Beneficiaries = () => {
	
    const $ = window.jQuery
    const navigate = useNavigate()
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const [sendmoneyapirespose, setSendmoneyapirespose] = useState([])
    const [beneficiaries, setBeneficiaries] = useState([])
    const issharedRef = useRef()
    const purposepaymentRef = useRef()
    const accountTypeRef = useRef()
    const selectIbanRef = useRef()
    const [ibans, setIbans] = useState([])
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const sendModalRef = useRef();
    const [selectedBeneficiary, setSelectedBeneficiary] = useState({})
    const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
    const [unique_id, setUnique_id] = useState('')
    const [isSendModalVisible, setIsSendModalVisible] = useState(false);
    const closeRef = useRef();
    const [OTP, setOTP] = useState('')
    const [confirmOTPLoading, setConfirmOTPLoading] = useState(false)
	
    const [defaultBencurrency, setDefaultBencurrency] = useState('EUR')
    const [accountType, setAccountType] = useState('sepa')
    const [showpaymentoption, setShowpaymentoption] = useState(false)
    const [shared, setShared] = useState(false)
	const [paymentoptions, setPaymentoptions] = useState([])
	
    const [filteredBeneficiaries,setFilteredBeneficiaries]=useState([])
    const [search,setSearch]=useState(false)
    useEffect(() => {
        const scriptUrls = [
            "js/plugins-init/select2-init.js",
            "vendor/select2/js/select2.full.min.js",
        ];
        scriptUrls.forEach((scriptUrl) => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
        });
        return () => {
            scriptUrls.forEach((scriptUrl) => {
                const script = document.querySelector(`[src="${scriptUrl}"]`);
                if (script) {
                    script.remove();
                }
            });
        };
    }, []);

	const sendmoneyModal = () => {
        setIsSendModalVisible(false); 
	
		setInputs({ amount: 0, reason_payment: '' })	
		setShowpaymentoption(false);
		setShared(false);
		setPaymentoptions('');	
    };
    const showOtpModal = () => {
        setIsOtpModalVisible(true);
    };

    const hideOtpModal = () => {
        setIsOtpModalVisible(false);        
        setOTP('')
    };
    const [validationRules, setValidationRules] = useState({
        amount: "Amount is required",
        reason_payment: "Payment reference is required",

    });
    const [selectValidationRules, setSelectValidationRules] = useState({
        payment_option: "Please Select Payment Option",
        iban_id: "Please Select the IBAN",


    })
    const [inputs, setInputs] = useState({
        amount: 0,
        reason_payment: '',

    })
    useEffect(() => {
        if (!authUser) {
            return navigate('/login')
        }

        if (authUser && !authUser?.web_token) {
            return navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                return navigate('/account-confirmation')
            }
            return navigate('/account-confirmation')
        }
        getBeneficiaries()
        getIBANList()
    }, [])
	
	const getpaymentcode = async (agr) => {
		getIBANList(agr);
		setShared(false);
		setShowpaymentoption(false);
		setPaymentoptions('');
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
			beneficiaries_id: agr?.beneficiaries_id
        }
		setSelectedBeneficiary(agr);
		setDefaultBencurrency(agr?.currency_flag);
		
        const url = API_ENDPOINTS.GET_PAYMENT_CODE
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
			
			
			setShowpaymentoption(true)
			setPaymentoptions(response?.pc);			
        } 
		//console.log(agr?.account_type);
		if(agr?.account_type == 'swift')
		{
			setShared(true)
		}
		if(agr?.account_type != 'sepa')
		{
			setAccountType(agr?.account_type)
		}
		
		//console.log("Shared "+shared);
		setIsSendModalVisible(true); 
    }
	
    const getBeneficiaries = async () => {
        setLoadingData(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }

        const url = API_ENDPOINTS.BENEFICIARIES_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setBeneficiaries(response?.beneficiary)
            setLoadingData(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch beneficiaries')
            setLoadingData(false)
        } else {
            console.error(response?.error || 'unable to fetch beneficiaries')
            setLoadingData(false)
        }
    }
	
	
    const getIBANList = async (agr) => {
        setLoadingData(true)
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
			beneficiaries_id: agr?.beneficiaries_id
        }

        const url = API_ENDPOINTS.IBAN_LIST
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            setIbans(response?.ibans)
            setLoadingData(false)
        } else if (response?.status == 0) {
            console.error(response?.message || 'unable to fetch ibans')
            setLoadingData(false)
        } else {
            console.error(response?.error || 'unable to fetch ibans')
            setLoadingData(false)
        }
    }
    const deleteBeneficiary = async (id) => {
        Swal.fire({
            title: 'Delete Beneficiary',
            text: 'Are you sure you want to delete the beneficiary!',
            icon: 'warning',
            background: '#5811CF',
            color: 'white',
            customClass: {
                title: 'text-white',
                text: 'text-white',
                confirmButton: 'btn-danger', // Use Bootstrap class for red color on Delete button
                cancelButton: 'btn-primary'
            },
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    const { web_token, user_id } = authUser
                    const body = {
                        whitelabel_id: whitelabel_id,
                        web_token: web_token,
                        user_id: user_id,
                        beneficiaries_id: id
                    }
                    const url = API_ENDPOINTS.DELETE_BENEFICIARY
                    const method = API_METHODS.POST
                    const response = await apiCall(url, method, body)
                    if (response?.status == 1) {
                        toastr.success(response?.message || 'Beneficiary delete successfully')
                        setLoading(false)
                        return response;
                    } else if (response?.status == 0) {
                        toastr.error(response?.message || 'unable to delete beneficiary')
                        setLoading(false)
                        return response;
                    } else {
                        toastr.error(response?.error || 'unable to delete beneficiary')
                        setLoading(false)
                        return response;
                    }
                } catch (error) {
                    toastr.error(error || 'unable to delete beneficiary')
                    setLoading(false)
                }
            },
        }).then(async (result) => {
            if (result?.value?.status == 1) {
                getBeneficiaries()
                Swal.fire({
                    title: 'Deleted!', text: 'Your item has been deleted.', icon: 'success', background: '#5811CF',
                    color: 'white', customClass: {
                        title: 'text-white',
                        text: 'text-white',
                        confirmButton: 'btn-danger',
                        cancelButton: 'btn-primary'
                    },
                });
            } else if (result?.value?.status == 0) {

            } else {
                Swal.fire({
                    title: 'Cancelled!', text: 'Your item is safe.', icon: 'info', background: '#5811CF',
                    color: 'white', customClass: {
                        title: 'text-white',
                        text: 'text-white',
                        confirmButton: 'btn-danger',
                        cancelButton: 'btn-primary'
                    },
                });
            }
        });
    }
    const sendMoney = async () => {
		
        setErrors({});
        if (!validateInputs()) {
            return;
        }
		 
        setLoading(true)
        const { web_token, user_id } = authUser       
        const method = API_METHODS.POST
		var url = '';
		var body = '';
		if(selectedBeneficiary?.account_type == 'local')
		{
			
			if(showpaymentoption == true)
			{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					purposepayment: purposepaymentRef?.current?.value,
				}
			}else{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
				}
			}
		}else if(selectedBeneficiary?.account_type == 'swift')
		{
			
			if(showpaymentoption == true)
			{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					is_shared: issharedRef?.current?.value,
					purposepayment: purposepaymentRef?.current?.value
				}
			}else{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					is_shared: issharedRef?.current?.value					
				}

			}
		}else{
			
			var body = {
				whitelabel_id: whitelabel_id,
				web_token: web_token,
				user_id: user_id,
				amount: inputs?.amount,
				reason_payment: inputs?.reason_payment,
				payment_option: accountTypeRef?.current?.value,
				iban_id: selectIbanRef?.current?.value,
				beneficiaries_id: selectedBeneficiary?.beneficiaries_id
			}
		}
		
		if(accountType == 'sepa')
		{
			url = API_ENDPOINTS.SEND_MONEY;
		}else{
			url = API_ENDPOINTS.SEND_SWIFT_MONEY;				
		}	
		
        const response = await apiCall(url, method, body)
        if (response?.status == 1) 
		{
			setSendmoneyapirespose(response);
            setUnique_id(response?.unique_id)
			sendmoneyModal();
            setInputs({ amount: 0, reason_payment: '' })
            $(selectIbanRef.current).val('').trigger('change')
            $(accountTypeRef.current).val('').trigger('change')
            showOtpModal()
            toastr.success(response?.message || 'Transferred Successfully')            
			
			setShowpaymentoption(false);
			setShared(false);
			setPaymentoptions('');
			
			setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message || 'unable to add Successfully')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to add Successfully')
            setLoading(false)
        }
    }
    const validateInputs = () => {
        const errors = {};
        Object.keys(validationRules).forEach((field) => {
            if (!inputs[field]) {
                errors[field] = validationRules[field];
            }
        });

		if(accountType == 'sepa')
		{
			if (!accountTypeRef.current.value) {
				errors.payment_option = selectValidationRules.payment_option;
			}
        }
        if (!selectIbanRef.current.value) {
            errors.iban_id = selectValidationRules.iban_id;
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const confirmOTP = async () => {
		var url = '';
        setConfirmOTPLoading(true)
        const { web_token, user_id } = authUser
        const method = API_METHODS.POST
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
            otp: OTP,
            unique_id: unique_id
        }
		
		if(accountType == 'sepa')
		{
			url = API_ENDPOINTS.CONFIRM_IBAN_OTP;
		}else{
			url = API_ENDPOINTS.CONFIRM_SWIFT_OTP;				
		}
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {
            toastr.success(response?.message || 'Transferred Successfully')
            setOTP('')
            setIsOtpModalVisible(false)
            setConfirmOTPLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message || 'unable to add Successfully')
            setConfirmOTPLoading(false)
        } else {
            console.error(response?.error || 'unable to add Successfully')
            setConfirmOTPLoading(false)
        }
    }
    const searchBeneficiary = (search)=>{
        
        const filtered = beneficiaries.filter((b)=>{
            return b.name.toLowerCase().includes(search.toLowerCase());
        })
        if(search == ''){
            setFilteredBeneficiaries(beneficiaries)
            setSearch(false)
        }else{
            setFilteredBeneficiaries(filtered)
            setSearch(true)
        }
        
    }
    return (
        <div >
            {/* row */}

            <div className="d-flex justify-content-between mb-4">
                <h3>Beneficiaries List</h3>
                <Link to={"/add-beneficiary"}>  <button type="button" class="btn btn-success btn-sm">Add Beneficiary</button></Link>
            </div>
            <div className="row mb-3">
                <div className="col-md-6" >
                <input type="text" name="search" class="form-control" placeholder="Enter name to search"  onChange={(e)=>{searchBeneficiary(e.target.value)}}/>

                </div>
            </div>
            {!loadingData ? <div className="col-lg-12">
                <div className="card">

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-md">
                                <thead>
                                    <tr>
                                       
                                        <th>
                                            <strong>IMAGE</strong>
                                        </th>
                                       
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>ACCOUNT HOLDER</strong>
                                        </th>
                                        <th >
                                            <strong>EMAIL</strong>
                                        </th>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>ACCOUNT TYPE</strong>
                                        </th>
                                        <th>
                                            <strong>ACCOUNT</strong>
                                        </th>
                                        <th>
                                            <strong>BIC/SWIFT/Bankcode</strong>
                                        </th>
										  <th>
                                            <strong>BANK COUNTRY</strong>
                                        </th>
                                        <th style={{ whiteSpace: 'wrap' }}>
                                            <strong>PAYMENT CURRENCY</strong>
                                        </th>
                                        <th>
                                            <strong>ACTION</strong>
                                        </th>
                                    </tr>	
                                </thead>
                                <tbody>
                                    {beneficiaries?.length > 0 && (search ? filteredBeneficiaries : beneficiaries).map((ben) => {
                                        return <tr>
                                         

                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        // src={ben?.image}
                                                        src={ben?.image ? ben?.image : require("../../../assets/user-default-image.png")}
                                                        className="rounded me-2"
                                                        width={50}
                                                        height={50}
                                                        alt=""
                                                    />{" "}

                                                </div>
                                            </td>
                                            <td>{ben?.name}</td>
                                            <td>{ben?.email}</td>
                                            <td>
                                                {(ben?.account_type).toUpperCase()}
                                            </td>
                                            <td>
                                                {ben?.iban}
                                            </td>
                                            <td>
                                                {ben?.bic} 												
                                            </td>
											 <td>                                                
												<img
													// src={ben?.image}
													src={ben?.flag ? ben?.flag : require("../../../assets/user-default-image.png")}
													className="rounded me-2"
													width={20}
													height={20}
													alt={ben?.currency}
												/>{" "}
												{ben?.bank_country} 

                                            </td>
                                            <td>
												<img
													// src={ben?.image}
													src={ben?.currency_flag ? ben?.currency_flag : require("../../../assets/user-default-image.png")}
													className="rounded me-2"
													width={20}
													height={20}
													alt={ben?.currency}
												/>{" "}
                                                {ben?.currency} 	
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                  
													 <a href="javascript:void(0)" className="btn btn-warning shadow btn-xs sharp mr-15" onClick={() => { getpaymentcode(ben) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-send mx-auto" viewBox="0 0 16 16">
                                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                        </svg>
                                                    </a>&nbsp;&nbsp;
													{/*
													
													 <a data-bs-toggle="modal" data-bs-target="#sendModal"
                                                        ref={sendModalRef}
                                                        href="#"
                                                        className="btn btn-danger shadow btn-xs sharp me-1"
                                                        onClick={() => { getpaymentcode(ben); }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-send mx-auto" viewBox="0 0 16 16">
                                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                        </svg>
                                                    </a>
													*/}
                                                    {/* <a
                                                        href="#"
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fas fa-pencil-alt" />
                                                    </a> */}
                                                    <a href="javascript:void(0)" className="btn btn-danger shadow btn-xs sharp mr-15" onClick={() => { deleteBeneficiary(ben?.beneficiaries_id) }}>
                                                        <i className="fa fa-trash" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    })}



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> :
                <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {/* send Modal */}
            
			  
			
			 <Modal
                visible={isSendModalVisible}
                onCancel={sendmoneyModal}
                footer={[
                    <div className="d-flex justify-content-end align-items-center gap-2">
                       
                    </div>
                ]}
                wrapClassName="custom-ant-modal"
                closable={false}
                maskClosable={false}
            >
                
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Send To {selectedBeneficiary?.name}</h5>
                            <button type="button" class="btn-close" onClick={sendmoneyModal}>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3 ">
                                <label class="form-label">Payment Amount <img
													// src={ben?.image}
													src={defaultBencurrency ? defaultBencurrency : require("../../../assets/user-default-image.png")}
													className="rounded me-2"
													width={20}
													height={20}
													alt={defaultBencurrency}
												/>{" "}
												</label>
                                <input value={inputs?.amount || ''}
                                    onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, amount: e.target.value }))}
                                    type="number" class="form-control" placeholder="Enter Amount" />
                                {errors.amount && (
                                    <span className="text-danger">{errors.amount}</span>
                                )}
                            </div>
                            <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Select IBAN</label>
                                    <select ref={selectIbanRef} class="default-placeholder form-control " style={{background: '#371f9b',color: '#fff'}} placeholder="Select IBAN">
                                        <option disabled selected value={""}>Select IBAN</option>
                                        {ibans?.length > 0 && ibans.map((iban) => {
                                            return <option value={iban.iban_id}>{iban.label} ({iban.balance} {iban.currency})</option>
                                        })}
                                    </select>
                                    {errors?.iban_id && (
                                        <span className="text-danger">{errors?.iban_id}</span>
                                    )}
                                </div>
                            </div>
							{accountType == 'sepa' &&
                            <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Payment Option</label>
                                    <select ref={accountTypeRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select IBAN">
                                        <option disabled selected value={""}>Select Payment Option</option>
                                        <option value={"sepa"}>Sepa</option>
                                        <option value={"instant"}>Sepa Instant</option>
                                    </select>
                                    {errors.payment_option && (
                                        <span className="text-danger">{errors.payment_option}</span>
                                    )}
                                </div>
                            </div>
							}
                            <div class="mb-3 ">
                                <label class="form-label">Payment Reference</label>
                                <input  value={inputs?.reason_payment || ''}
                                    onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, reason_payment: e.target.value }))}
                                    type="text" class="form-control" placeholder="Reference" />
                                {errors.reason_payment && (
                                    <span className="text-danger">{errors.reason_payment}</span>
                                )}
                            </div>
							{shared == true &&
							
							 <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Shared Cost</label>
                                    <select ref={issharedRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select Shared Cost">
                                        <option value={"0"}>No</option>
                                        <option value={"1"}>Yes</option>
                                    </select>
                                </div>
                            </div>
							}
							
							{showpaymentoption == true &&
							 <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Select Purpose of Payment</label>
                                    <select ref={purposepaymentRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select Purpose of Payment">                                       
                                        {paymentoptions?.length > 0 && paymentoptions.map((k) => {
                                            return <option value={k.payment_code}>{k.payment_description}</option>
                                        })}
                                    </select>                                   
                                </div>
                            </div>
							}
                            <div className="d-flex justify-content-end">
                                {/* <button onClick={sendMoney} type="button" class="btn btn-success btn-sm">Send Now</button> */}

                                <button disabled={loading} type="button" onClick={sendMoney} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                    Send Now
                                    {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>
                
            {/* send Modal */}
            <Modal
                visible={isOtpModalVisible}
                onCancel={hideOtpModal}
                footer={[
                    <div className="d-flex justify-content-end align-items-center gap-2">
                        <button type="button" onClick={hideOtpModal} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                            Cancel
                        </button>

                        <button disabled={confirmOTPLoading} type="button" onClick={confirmOTP} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                            Confirm
                            {confirmOTPLoading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                <span class="sr-only">Loading...</span>
                            </div>}
                        </button>
                    </div>
                ]}
                wrapClassName="custom-ant-modal"
                closable={false}
                maskClosable={false}
            >
                <div className="text-white">
                    <h3>Confirm OTP</h3>
                    <span>An OTP is sent to your email . Please Enter OTP below to confirm transfer.</span>
                </div>
                <input
                    value={OTP || ''}
                    onChange={(e) => setOTP(e.target.value)}
                    type="number" class="form-control" placeholder="Enter OTP" />

            </Modal>

        </div>
    )

}
const CustomModalHeader = ({ title }) => (
    <div style={{ backgroundColor: '#5811CF', padding: '16px', color: 'white' }}>
        {title}
    </div>
);
export default Beneficiaries