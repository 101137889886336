import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { whitelabel_id } from "../../constants/ids"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import apiCall from "../../utils/apiCall"
import toastr from 'toastr'
import { useTheme } from "../Theme/themeContext"
import { cardBackground, textColor } from "../Theme/lightThemeStyles"
import { Modal } from "antd"
const Dashboard = () => {
	const $ = window.jQuery
	const navigate = useNavigate()
	const statuses = JSON.parse(localStorage.getItem('statuses'))
	const authUser = JSON.parse(localStorage.getItem('auth_user'))
	const [transactions, setTransactions] = useState([])
	const [beneficiaries, setBeneficiaries] = useState([])
	const [portFolio, setPortFolio] = useState("")
	const [portFoliocurrency, setPortFoliocurrency] = useState("")
	const [portFoliocurrencyFlag, setPortFoliocurrencyFlag] = useState("")
	const [ibans, setIbans] = useState([])
	const [sendmoneyIbans, setSendmoneyIbans] = useState([])
	const [userPlan, setUserPlan] = useState({})
	const [email, setEmail] = useState('')
	const [label, setLabel] = useState('')
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [createIBANLoading, setCreateLabelLoading] = useState(false)
	const [shareIBANloading, setShareIbanLoading] = useState(false)
	const [selectedIBAN, setSelectedIBAN] = useState({})
	const [debitCards, setDebitCards] = useState([])
	const [selectedIbanForDetails, setSelectedIbanForDetails] = useState('')
	const [balanceTitle, setBalanceTitle] = useState('')

	const closeModalRef = useRef()
	const createNewIbanModalRef = useRef()
	const openIbanAccountsModalRef = useRef()
	const shareIBANRef = useRef()
	const { lightTheme, toggleTheme } = useTheme();
	const [selectedBeneficiary, setSelectedBeneficiary] = useState({})
	const selectIbanRef = useRef()
	const [OTP, setOTP] = useState('')
	const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
	const closeRef = useRef();
	const accountTypeRef = useRef();
	const [unique_id, setUnique_id] = useState('')
  
	const [sendmoneyapirespose, setSendmoneyapirespose] = useState([])
	const [confirmOTPLoading, setConfirmOTPLoading] = useState(false)
	const [isSendModalVisible, setIsSendModalVisible] = useState(false);	
	const [defaultBencurrency, setDefaultBencurrency] = useState('EUR')
	const [accountType, setAccountType] = useState('sepa')
	const [showpaymentoption, setShowpaymentoption] = useState(false)
	const [shared, setShared] = useState(false)
	const [paymentoptions, setPaymentoptions] = useState([])
	const issharedRef = useRef()
	const purposepaymentRef = useRef()


	const [inputs, setInputs] = useState({
		amount: 0,
		reason_payment: '',

	})
	const [validationRules, setValidationRules] = useState({
		amount: "Amount is required",
		reason_payment: "Payment reference is required",

	});
	const [selectValidationRules, setSelectValidationRules] = useState({
		payment_option: "Please Select Payment Option",
		iban_id: "Please Select the IBAN",

	})

	useEffect(() => {
    const scriptUrls = [
      "js/plugins-init/select2-init.js",
      "vendor/select2/js/select2.full.min.js",
    ];
    scriptUrls.forEach((scriptUrl) => {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.async = true;
      document.body.appendChild(script);
    });
    return () => {
      scriptUrls.forEach((scriptUrl) => {
        const script = document.querySelector(`[src="${scriptUrl}"]`);
        if (script) {
          script.remove();
        }
      });
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
		getDashboardDetails(false);
    }, 60000);
    return () => clearInterval(intervalId);
  }, [selectedIbanForDetails]);
  useEffect(() => {

    if (!authUser) {
      return navigate('/login')
    }
    if (authUser && !authUser?.web_token) {
      return navigate('/login')
    }
    if (authUser && authUser?.is_live !== 1) {
      // if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
      //   return navigate('/account-confirmation')
      // }
      return navigate('/account-confirmation')
    }
    getDashboardDetails(true)
    getDebitCards()
  }, [])
  useEffect(() => {
    if (selectedIbanForDetails) {
      getDashboardDetails()
    }
  }, [selectedIbanForDetails])
  const getDashboardDetails = async (loading) => {
    if (loading) {
      setLoadingData(true)
    }
    const { web_token, user_id } = authUser
    const url = API_ENDPOINTS.GET_DASHBOARD_DETAILS
    const body = {
      whitelabel_id: whitelabel_id,
      web_token: web_token,
      user_id: user_id,
      iban_id: selectedIbanForDetails
    }
    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      setBalanceTitle(response?.balance_label)
      setIbans(response?.ibans)
      setPortFoliocurrencyFlag(response?.currency_portfolio_flag)
      setPortFoliocurrency(response?.currency_portfolio)
      setPortFolio(response?.portfolio)
      setBeneficiaries(response?.beneficiaries)
      setUserPlan(response?.userplan)
      setTransactions(response?.transactions)
      setLoadingData(false)
    } else if (response?.status == 0) {
      toastr.error(response?.message)
      setLoadingData(false)

    } else {
      console.error('Unable to fetch.')
    }
  }

  const shareIBAN = async (ibanId) => {
    setErrors({})
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email == '') {
      setErrors({ email: 'email is required' })
      return;
    }
    if (!emailRegex.test(email)) {
      setErrors({ email: 'Please enter a valid email address' });
      return;

    }
    setShareIbanLoading(true)
    const { web_token, user_id } = authUser
    const url = API_ENDPOINTS.SHARE_IBAN
    const body = {
      whitelabel_id: whitelabel_id,
      web_token: web_token,
      user_id: user_id,
      email: email,
      iban_id: selectedIBAN?.iban_id
    }
    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      toastr.success(response?.message || 'IBAN shared successfully.')
      setEmail('')
      shareIBANRef.current.click()
      setShareIbanLoading(false)
    } else if (response?.status == 0) {
      toastr.error(response?.message)
      setEmail('')
      setShareIbanLoading(false)
    } else {
      console.error('Unable to fetch.')
    }
  }
  const createIBAN = async (ibanId) => {
    setErrors({})
    if (label == '') {
      setErrors({ label: 'Label is required' })
      return;
    }
    setCreateLabelLoading(true)
    const { web_token, user_id } = authUser
    const url = API_ENDPOINTS.CREATE_IBAN
    const body = {
      whitelabel_id: whitelabel_id,
      web_token: web_token,
      user_id: user_id,
      label: label,
    }
    // getDashboardDetails()

    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      toastr.success(response?.message || 'IBAN created successfully.')
      getDashboardDetails()
      setCreateLabelLoading(false)
      createNewIbanModalRef.current.click()
      openIbanAccountsModalRef.current.click()
    } else if (response?.status == 0) {
      toastr.error(response?.message)

      setCreateLabelLoading(false)
    } else {
      console.error('Unable to fetch.')
    }
  }

  const getDebitCards = async () => {
    setLoadingData(true)
    const { web_token, user_id } = authUser
    const url = API_ENDPOINTS.GET_DEBIT_CARDS
    const body = {
      whitelabel_id: whitelabel_id,
      web_token: web_token,
      user_id: user_id,
    }
    const response = await apiCall(url, API_METHODS.POST, body)
    if (response?.status == 1) {
      setDebitCards(response?.cardList)
    } else if (response?.status == 2) {
      setDebitCards([])
      console.error(response?.message)
      setLoadingData(false)

    } else if (response?.status == 0) {
      setDebitCards([])
      console.error(response?.message)
      setLoadingData(false)

    } else {
      console.error('Unable to fetch.')
    }
  }
	const sendmoneyModal = () => {
		setIsSendModalVisible(false); 

		setInputs({ amount: 0, reason_payment: '' })	
		setShowpaymentoption(false);
		setShared(false);
		setPaymentoptions('');	
	};
  const validateInputs = () => {
    const errors = {};
    Object.keys(validationRules).forEach((field) => {
      if (!inputs[field]) {
        errors[field] = validationRules[field];
      }
    });
	
	if(accountType == 'sepa')
	{
		if (!accountTypeRef.current.value) {
			errors.payment_option = selectValidationRules.payment_option;
		}
	}

    if (!selectIbanRef.current.value) {
      errors.iban_id = selectValidationRules.iban_id;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const getIBANList = async (agr) => {	
	const { web_token, user_id } = authUser
	const body = {
		whitelabel_id: whitelabel_id,
		web_token: web_token,
		user_id: user_id,
		beneficiaries_id: agr?.beneficiaries_id
	}

	const url = API_ENDPOINTS.IBAN_LIST
	const method = API_METHODS.POST
	const response = await apiCall(url, method, body)
	if (response?.status == 1) {
		setSendmoneyIbans(response?.ibans)		
	}
  }

  
  const getpaymentcode = async (agr) => {
        getIBANList(agr);
		setShared(false);
		setShowpaymentoption(false);
		setPaymentoptions('');
        const { web_token, user_id } = authUser
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
			beneficiaries_id: agr?.beneficiaries_id
        }
		setSelectedBeneficiary(agr);
		setDefaultBencurrency(agr?.currency_flag);
		
        const url = API_ENDPOINTS.GET_PAYMENT_CODE
        const method = API_METHODS.POST
        const response = await apiCall(url, method, body)
        if (response?.status == 1) {			
			
			setShowpaymentoption(true)
			setPaymentoptions(response?.pc);			
        } 
		//console.log(agr?.account_type);
		if(agr?.account_type == 'swift')
		{
			setShared(true)
		}
		if(agr?.account_type != 'sepa')
		{
			setAccountType(agr?.account_type)
		}
		
		//console.log("Shared "+shared);
		setIsSendModalVisible(true); 
    }
  
  const sendMoney = async () => {
	
	 setErrors({});
        if (!validateInputs()) {
            return;
        }
		 
        setLoading(true)
        const { web_token, user_id } = authUser       
        const method = API_METHODS.POST
		var url = '';
		var body = '';
		if(selectedBeneficiary?.account_type == 'local')
		{
			
			if(showpaymentoption == true)
			{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					purposepayment: purposepaymentRef?.current?.value,
				}
			}else{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
				}
			}
		}else if(selectedBeneficiary?.account_type == 'swift')
		{
			
			if(showpaymentoption == true)
			{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					is_shared: issharedRef?.current?.value,
					purposepayment: purposepaymentRef?.current?.value
				}
			}else{
				var body = {
					whitelabel_id: whitelabel_id,
					web_token: web_token,
					user_id: user_id,
					amount: inputs?.amount,
					reason_payment: inputs?.reason_payment,
					payment_option: 'sepa',
					iban_id: selectIbanRef?.current?.value,
					beneficiaries_id: selectedBeneficiary?.beneficiaries_id,
					is_shared: issharedRef?.current?.value					
				}

			}
		}else{
			
			var body = {
				whitelabel_id: whitelabel_id,
				web_token: web_token,
				user_id: user_id,
				amount: inputs?.amount,
				reason_payment: inputs?.reason_payment,
				payment_option: accountTypeRef?.current?.value,
				iban_id: selectIbanRef?.current?.value,
				beneficiaries_id: selectedBeneficiary?.beneficiaries_id
			}
		}
		
		if(accountType == 'sepa')
		{
			url = API_ENDPOINTS.SEND_MONEY;
		}else{
			url = API_ENDPOINTS.SEND_SWIFT_MONEY;				
		}	
		
        const response = await apiCall(url, method, body)
        if (response?.status == 1) 
		{
			setSendmoneyapirespose(response);
            setUnique_id(response?.unique_id)
			sendmoneyModal();
            setInputs({ amount: 0, reason_payment: '' })
            $(selectIbanRef.current).val('').trigger('change')
            $(accountTypeRef.current).val('').trigger('change')
            showOtpModal()
            toastr.success(response?.message || 'Transferred Successfully')            
			
			setShowpaymentoption(false);
			setShared(false);
			setPaymentoptions('');
			
			setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message || 'unable to add Successfully')
            setLoading(false)
        } else {
            console.error(response?.error || 'unable to add Successfully')
            setLoading(false)
        } 
  }
  const confirmOTP = async () => {
    setConfirmOTPLoading(true)
    const { web_token, user_id } = authUser
    var url = ''
    const method = API_METHODS.POST
	
	if(accountType == 'sepa')
	{
		url = API_ENDPOINTS.CONFIRM_IBAN_OTP;
	}else{
		url = API_ENDPOINTS.CONFIRM_SWIFT_OTP;				
	}
    const body = {
      whitelabel_id: whitelabel_id,
      web_token: web_token,
      user_id: user_id,
      otp: OTP,
      unique_id: unique_id
    }
    const response = await apiCall(url, method, body)
    if (response?.status == 1) {
      toastr.success(response?.message || 'Transferred Successfully')
      setOTP('')
      setIsOtpModalVisible(false)
      setConfirmOTPLoading(false)
    } else if (response?.status == 0) {
      toastr.error(response?.message || 'unable to add Successfully')
      setConfirmOTPLoading(false)
    } else {
      console.error(response?.error || 'unable to add Successfully')
      setConfirmOTPLoading(false)
    }
  }
  const showOtpModal = () => {
    setIsOtpModalVisible(true);
  };

  const hideOtpModal = () => {
    setIsOtpModalVisible(false);
    setOTP('')
  };
  return (
    <div >
      {/* row */}

      {!loadingData ?
        <div className="row">
          <div className="col-xl-9">
            <div className="row">
              {/*--column-- */}
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 "  >
                <div className="card  wallet" >
                  <div className="boxs" >
                    <span className="box one " />
                    <span className="box two" />
                    <span className="box three" />
                    <span className="box four" />
                  </div>
                  <div className="card-header border-0 pe-5">
                    <div className="wallet-icon">
                     <img  src={portFoliocurrencyFlag ? portFoliocurrencyFlag : ''} alt="" width={32} height={32} style={{ borderRadius: '50%' }} />
                    </div>
                    {/* <div >
                      <div className="send m-auto" style={{ zIndex: '0' }}>
                        <svg
                          width={28}
                          height={28}
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity={1}
                            d="M15.1667 4.66667C15.1667 4.02233 14.6444 3.5 14 3.5C13.3557 3.5 12.8334 4.02233 12.8334 4.66667V18.6667C12.8334 19.311 13.3557 19.8333 14 19.8333C14.6444 19.8333 15.1667 19.311 15.1667 18.6667V4.66667Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                          <path
                            d="M7.825 12.4916C7.36939 12.9472 6.63069 12.9472 6.17508 12.4916C5.71947 12.036 5.71947 11.2973 6.17508 10.8417L13.1751 3.84171C13.6168 3.40003 14.3279 3.38458 14.7884 3.80665L21.7884 10.2233C22.2634 10.6587 22.2954 11.3967 21.8601 11.8717C21.4247 12.3467 20.6867 12.3787 20.2117 11.9433L14.0351 6.2815L7.825 12.4916Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                          <path
                            opacity={1}
                            d="M23.3333 22.1667H4.66667C4.02233 22.1667 3.5 22.689 3.5 23.3334C3.5 23.9777 4.02233 24.5 4.66667 24.5H23.3333C23.9777 24.5 24.5 23.9777 24.5 23.3334C24.5 22.689 23.9777 22.1667 23.3333 22.1667Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                        </svg>
                      </div>
                      <span>Transfer </span>
                    </div> */}
                  </div>
                  <div className="card-body py-3  d-flex align-items-center justify-content-between flex-wrap" >
                    <div className="wallet-info">
                      <span className="fs-18 d-block" >{balanceTitle}</span>
                      <h1 className="font-w600 mb-0 d-inline-flex me-2 text-break d-flex align-items-center" >
					  {portFolio} {portFoliocurrency} 
                      </h1>
                      {/* <span>+0.8% than last week</span> */}
                    </div>
                    {/* <div>
                      <div className="send m-auto" style={{ zIndex: '0', }}>
                        <svg
                          width={28}
                          height={28}
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M6.83329 2.33331H16.0258C16.4396 2.33331 16.84 2.47994 17.1559 2.74718L22.7134 7.44791C23.1066 7.78042 23.3333 8.26917 23.3333 8.78405V23.4305C23.3333 25.5195 23.3094 25.6666 21.1666 25.6666H6.83329C4.69048 25.6666 4.66663 25.5195 4.66663 23.4305V4.56942C4.66663 2.48046 4.69048 2.33331 6.83329 2.33331Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                          <path
                            d="M16.3333 12.8333H8.16667C7.52233 12.8333 7 13.3557 7 14C7 14.6443 7.52233 15.1667 8.16667 15.1667H16.3333C16.9777 15.1667 17.5 14.6443 17.5 14C17.5 13.3557 16.9777 12.8333 16.3333 12.8333Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                          <path
                            d="M11.6667 17.5H8.16667C7.52233 17.5 7 18.0223 7 18.6667C7 19.311 7.52233 19.8333 8.16667 19.8333H11.6667C12.311 19.8333 12.8333 19.311 12.8333 18.6667C12.8333 18.0223 12.311 17.5 11.6667 17.5Z"
                            fill={`${lightTheme ? '#808080' : 'white'}`}
                          />
                        </svg>
                      </div>
                      <span>Send Invoices</span>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* widget cards */}
              <div className="col-xl-7 ">
                <div className="card pt-2" >
                  <div className="card-body py-3 py-md-2 px-4">
                    <div className="row">
                      <div class="col-xl-4 col-lg-6 col-xxl-4 col-sm-12 mt-4 ">
                        <div class="card " ref={openIbanAccountsModalRef} data-bs-toggle="modal" data-bs-target="#exampleModalCenter" style={{
                          cursor: 'pointer',
                        }}>
                          <div class={`card-body text-center ai-icon  ${lightTheme ? "text-muted" : "text-primary"}`}>
                            <svg id="rocket-icon" class="my-2" viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                              <line x1="3" y1="6" x2="21" y2="6"></line>
                              <path d="M16 10a4 4 0 0 1-8 0"></path>
                            </svg>
                            <div>
                              <span className="text-white">Account Details</span>

                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-xl-3 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                        <div class="card " data-bs-toggle="modal" data-bs-target="#userPlan" style={{
                          cursor: 'pointer',
                        }} >
                          <div class="card-body text-center ai-icon  text-primary">
                            <svg id="rocket-icon" class="my-2" viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                              <line x1="3" y1="6" x2="21" y2="6"></line>
                              <path d="M16 10a4 4 0 0 1-8 0"></path>
                            </svg>
                            <div>
                              <span class="text-white">Plan</span>

                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div class="col-xl-4 col-lg-6 col-xxl-4 col-sm-12 mt-4 ">
                        <Link to={"/beneficiaries"}>
                          <div class="card " style={{
                            cursor: 'pointer'
                          }} >
                            <div class={`card-body text-center ai-icon  ${lightTheme ? "text-muted" : "text-primary"}`}>
                              <svg id="rocket-icon" class="my-2" viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <path d="M16 10a4 4 0 0 1-8 0"></path>
                              </svg>
                              <div>
                                <span class='text-white' >Beneficiary</span>

                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div class="col-xl-4 col-lg-6 col-xxl-4 col-sm-12 mt-4 ">

                        <div class="card " data-bs-toggle="modal" data-bs-target="#debitCard" style={{
                          cursor: 'pointer'
                        }} >
                          <div class={`card-body text-center ai-icon  ${lightTheme ? "text-muted" : "text-primary"}`}>
                            <svg id="rocket-icon" class="my-2" viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                              <line x1="3" y1="6" x2="21" y2="6"></line>
                              <path d="M16 10a4 4 0 0 1-8 0"></path>
                            </svg>
                            <div>
                              <span class='text-white' >Debit Card</span>

                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <div class="col-xl-3 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                      <div class="card " data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                        <div class="card-body text-center ai-icon  text-primary">
                          <svg id="rocket-icon" class="my-2" viewBox="0 0 24 24" width="40" height="40" stroke="currentColor" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <path d="M16 10a4 4 0 0 1-8 0"></path>
                          </svg>
                          <div>
                            <span class="text-white">Payments</span>

                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>

                </div>
              </div>




              {/*--/Benificiary-- */}
              {/* <div className="col-xl-12 ">
                <div className="card">
                  <div className="card-header">
                    <h6 className="card-title">Recent Benificiaries</h6>
                    <button type="button" class="btn btn-success btn-sm">Add Benificiary</button>
                  </div>
                  <div className="card-body py-3 py-md-2 px-4">
                    <div className="row">
                      <div class="col-xl-4 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                        <div className="card overflow-hidden">
                          <div
                            className="text-center p-3 overlay-box "
                            style={{ backgroundImage: "url(images/big/img1.jpg)" }}
                          >
                            <div className="profile-photo">
                              <img
                                src="images/profile/profile.png"
                                width={100}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-3 mb-1 text-white">Deangelo Sena</h3>
                            <p className="text-white mb-0">Senior Manager</p>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Patient Gender</span>{" "}
                              <strong className="">Female </strong>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Years Old</span>{" "}
                              <strong className="">Age: 24 </strong>
                            </li>
                          </ul>
                          <div className="card-footer border-0 mt-0">
                            <button className="btn btn-primary btn-lg btn-block">
                              <i className="fa fa-bell-o" /> View Profile
                            </button>
                          </div>
                        </div>


                      </div>
                      <div class="col-xl-4 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                        <div className="card overflow-hidden">
                          <div
                            className="text-center p-3 overlay-box "
                            style={{ backgroundImage: "url(images/big/img1.jpg)" }}
                          >
                            <div className="profile-photo">
                              <img
                                src="images/profile/profile.png"
                                width={100}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-3 mb-1 text-white">Deangelo Sena</h3>
                            <p className="text-white mb-0">Senior Manager</p>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Patient Gender</span>{" "}
                              <strong className="">Female </strong>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Years Old</span>{" "}
                              <strong className="">Age: 24 </strong>
                            </li>
                          </ul>
                          <div className="card-footer border-0 mt-0">
                            <button className="btn btn-primary btn-lg btn-block">
                              <i className="fa fa-bell-o" /> View Profile
                            </button>
                          </div>
                        </div>

                      </div>
                      <div class="col-xl-4 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                        <div className="card overflow-hidden">
                          <div
                            className="text-center p-3 overlay-box "
                            style={{ backgroundImage: "url(images/big/img1.jpg)" }}
                          >
                            <div className="profile-photo">
                              <img
                                src="images/profile/profile.png"
                                width={100}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-3 mb-1 text-white">Deangelo Sena</h3>
                            <p className="text-white mb-0">Senior Manager</p>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Patient Gender</span>{" "}
                              <strong className="">Female </strong>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Years Old</span>{" "}
                              <strong className="">Age: 24 </strong>
                            </li>
                          </ul>
                          <div className="card-footer border-0 mt-0">
                            <button className="btn btn-primary btn-lg btn-block">
                              <i className="fa fa-bell-o" /> View Profile
                            </button>
                          </div>
                        </div>


                      </div>
                      <div class="col-xl-4 col-lg-6 col-xxl-3 col-sm-12 mt-4 ">
                        <div className="card overflow-hidden">
                          <div
                            className="text-center p-3 overlay-box "
                            style={{ backgroundImage: "url(images/big/img1.jpg)" }}
                          >
                            <div className="profile-photo">
                              <img
                                src="images/profile/profile.png"
                                width={100}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-3 mb-1 text-white">Deangelo Sena</h3>
                            <p className="text-white mb-0">Senior Manager</p>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Patient Gender</span>{" "}
                              <strong className="">Female </strong>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                              <span className="mb-0">Years Old</span>{" "}
                              <strong className="">Age: 24 </strong>
                            </li>
                          </ul>
                          <div className="card-footer border-0 mt-0">
                            <button className="btn btn-primary btn-lg btn-block">
                              <i className="fa fa-bell-o" /> View Profile
                            </button>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
              </div> */}
              {/*Benificiary*/}
              <div className="col-xl-12">
                <div className="card" >
                  <div class="mt-4 ms-4">
                    <h4 className="fs-18 font-w600." >Latest Transactions</h4>
                  </div>
                  <div className="card-body">
                    {transactions?.length > 0 ? <div className="table-responsive">
                      <table className="table table-responsive-md">
                        <thead>
                          <tr>


                            {/* <th>
                                            <strong>WALLET</strong>
                                        </th> */}									
                            <th>
                              <strong >TYPE</strong>
                            </th>
							 <th>
                              <strong >PAYMENT TYPE</strong>
                            </th>	
                            <th>
                              <strong >TOTAL</strong>
                            </th>
                            <th>
                              <strong >DESCRIPTION</strong>
                            </th>
                            <th>
                              <strong >STATUS</strong>
                            </th>
                            <th>
                              <strong >DATE</strong>
                            </th>

                          </tr>
                        </thead>
                        <tbody >

                          {transactions?.length > 0 && (transactions).map((trx) => {
                            return <tr>
                              {/* <td>{trx?.label}</td> */}
							 
                              <td >
								
                                <span class="badge light badge-dark text-white d-flex justify-content-center align-items-center text-center" >
                                  {trx?.type == 'credit' ? <i class="bi bi-arrow-down-circle me-2 text-success" style={{ fontSize: '16px' }}></i> :
                                    <i class="bi bi-arrow-up-circle me-2 text-danger" style={{ fontSize: '16px' }}></i>}
                                  {trx?.type}</span>
                                {/* <button type="button" class="btn light btn-light btn-xxs d-flex align-items-center" >
                                  <i class="bi bi-arrow-up-circle me-2 text-danger" style={{ fontSize: '16px' }}></i>
                                  {trx?.type}
                                </button> */}
                              </td>
							   <td >{trx?.payment_mode}</td>
                              <td >{trx?.amount}</td>
                              <td >
                                {trx?.description}
                              </td>
                              <td >
                                {trx?.status == 'Pending' ? <i class="bi bi-hourglass-split me-1 text-info"></i> :
                                  <i class="bi bi-check2-circle me-1 text-success"></i>
                                }

                                {trx?.status}
                              </td>
                              <td >
                                {trx?.created}
                              </td>
                              {/* <td>

                                                <button type="button" class="btn  btn-info btn-xxs d-flex align-items-center">
                                                    <i class="bi bi-eye me-2" style={{ fontSize: '16px' }}></i>
                                                    View</button>

                                            </td> */}
                              {/* transaction view Modal */}
                              {/* <div class="modal fade" id="transaction-view">
                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Transaction detail</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div className="d-flex justify-content-between">
                                                                <span >Plane Name</span>
                                                                <span >{userPlan?.plan_name}</span>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between">
                                                                <span >No Plastic Card</span>
                                                                <span >{userPlan?.no_plastic_card}</span>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between">
                                                                <span >No VC</span>
                                                                <span >{userPlan?.no_vc}</span>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between">
                                                                <span >No VPC</span>
                                                                <span >{userPlan?.no_vpc}</span>
                                                            </div>
                                                            <hr />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}
                              {/* transaction view Modal */}
                            </tr>
                          })}



                        </tbody>
                      </table>
                    </div> :

                      <div class="container h-100">

                        No Transactions found
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/*/column*/}
            </div>
            {/* --/row-- */}
            {/*/column*/}
          </div>
          {/*column*/}
          <div className="col-xl-3">
          
            {/* --/row-- */}
          
              <div className="card Invoices mb-5 "  >
                <div className="card-header border-0  pb-0">
                  <div>
                    <h4 className="fs-18 font-w600." >Beneficiaries</h4>
                    <span className="fs-14 font-w400">You have {beneficiaries?.length} beneficiaries</span>
                  </div>
                </div>
                <div
                  className="card-body loadmore-content recent-activity-wrapper-invoice dz-scroll px-3 py-2 ps ps--active-y overflow-auto scrollHide"
                  id="RecentActivityContent"
                >

                  {beneficiaries?.length > 0 &&
                    beneficiaries?.map((ben) => {
                      return <div style={{ cursor: 'pointer' }} className="d-flex py-sm-3 py-1 px-3 align-items-center student"href="javascript:void(0)" onClick={() => { getpaymentcode(ben) }}>
                        <span className=" me-3">
                          <img src={ben?.image ? ben?.image : require("../../assets/user-default-image.png")} alt="" width={50} height={50} />
                        </span>
                        <div>
                          <h6 className="font-w500 fs-16 mb-0">
                            <a href="javascript:void(0)" >{ben?.name} <img
													// src={ben?.image}
													src={ben?.currency_flag ? ben?.currency_flag : require("../../../assets/user-default-image.png")}
													className="rounded me-2"
													width={20}
													height={20}
													alt={ben?.currency}
												/>{" "}</a>
                          </h6>
                          <span className="fs-14 font-w400">{ben?.iban}</span><br/>
                          <span className="fs-14 font-w400">{ben?.bic}</span>
                        </div>
                      </div>
                     
                    })
                  }

                  {/* <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic2.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Evans John</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap text-break">
                      Programmer
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic3.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Brian Brandon</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap	">
                      Graphic Designer
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic3.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Brian Brandon</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap	">
                      Graphic Designer
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic4.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Bella Brownlee</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap	">
                      Software Engineer
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic5.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Chynthia Lawra</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap">CEO</span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic6.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Dedi Cahyadi</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap">
                      Head Manager
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img
                      src="./images/profile/small/pic7.jpg"
                      alt=""
                      width={50}
                    />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Evans John</a>
                    </h6>
                    <span className="fs-14 font-w400 text-wrap text-break">
                      Programmer
                    </span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div>
                <div className="d-flex py-sm-3 py-1 px-3 align-items-center student">
                  <span className=" me-3">
                    <img src="images/profile/small/pic1.jpg" alt="" width={50} />
                  </span>
                  <div>
                    <h6 className="font-w500 fs-16 mb-0">
                      <a href="app-profile.html">Dedi Cahyadi</a>
                    </h6>
                    <span className="fs-14 font-w400">Head Manager</span>
                  </div>
                  <div className="price ms-auto">
                    <a href="#">$776</a>
                  </div>
                </div> */}
                </div>
                <div className="card-footer text-center border-0 pt-0">
                  <Link to={"/beneficiaries"}
                    className={`btn btn-block   btn-primary py-2`}
                    id="RecentActivity"
                    rel="ajax/recentactivity.html"
                  >
                    View All
                  </Link>
                </div>
              </div>
            
          </div>
          {/*--/column-- */}
          {/*/row*/}
        </div> :
        <div className="text-center">

          <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      }
      {/*/column*/}
      {/***********************************
					Footer start
				************************************/}

      {/* Modal IBAN*/}
      <div class="modal fade" id="exampleModalCenter">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Bank Accounts</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" ref={closeModalRef}>
              </button>
            </div>
            <div class="modal-body scrollHide" style={{ maxHeight: '500px', overflow: 'auto' }}>
              <div className="mb-2 d-flex justify-content-end">
                <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#createIBAN">+ NEW IBAN</button>
              </div>
              {ibans?.length > 0 ?
                ibans?.map((ib) => {
                  return <div class="widget-stat card" style={{ cursor: 'pointer' }} onClick={() => { closeModalRef.current.click(); setSelectedIbanForDetails(ib?.iban_id) }}>
                    <div class="card-body p-4">

                      <div class="media ai-icon">
                        <span class="me-3 bgl-success text-success">
                           <img src={ib?.flag ? ib?.flag : ''} alt="" width={32} height={32} style={{ borderRadius: '50%' }} />
                        </span>
                        <div class="media-body">
                          <div className="d-flex justify-content-between mb-1">
                            <h6 >{ib?.label}</h6>
                            <h6 className="text-secondary d-flex align-items-center">{ib?.balance} {ib?.currency}</h6>
                          </div>
                          <span class="mb-0">{ib?.account_type}: {ib?.iban}</span> <br />
                          <span class="mb-0">{ib?.routing_type}:{ib?.bic}</span>

                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button onClick={(e) => { e.stopPropagation(); setSelectedIBAN(ib) }} type="button" class="btn btn-xs light btn-primary" data-bs-toggle="modal" data-bs-target="#shareIBAN">Share</button>

                      </div>
                    </div>

                  </div>
                })
                : 'no IBAN'}

            </div>

          </div>
        </div>
      </div>
      {/* /Modal IBAN*/}

      {/* Modal Create IBANE*/}
      <div class="modal fade" id="createIBAN">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create new IBAN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" ref={createNewIbanModalRef}>
              </button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <input
                  type="text"
                  onChange={(e) => { setLabel(e.target.value) }}
                  class="form-control input-rounded" placeholder="IBAN Label" />
                {errors?.label &&
                  <span className="text-danger">{errors?.label}</span>
                }
              </div>

              <div className="d-flex justify-content-end">
                <button disabled={createIBANLoading} type="button" onClick={createIBAN} class="btn btn-success btn-sm d-flex justify-content-center align-items-center gap-2">
                  Create New IBAN
                  {createIBANLoading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                    <span class="sr-only">Loading...</span>
                  </div>}
                </button>

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* /Modal Create IBAN*/}
      {/* Modal Share IBANE*/}
      <div class="modal fade" id="shareIBAN">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Share IBAN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" ref={shareIBANRef}>
              </button>
            </div>
            <div class="modal-body">
              <div className="mb-2">
                <strong>IBAN: </strong>  <span>{selectedIBAN?.iban}</span>
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  class="form-control input-rounded" placeholder="Enter the email to share the IBAN. " />
                {errors?.email &&
                  <span className="text-danger">{errors?.email}</span>
                }
              </div>

              <div className="d-flex justify-content-end">
                <button disabled={shareIBANloading} type="button" onClick={() => { shareIBAN(ibans[0]?.iban_id) }} class="btn btn-success btn-sm d-flex justify-content-center align-items-center gap-2">
                  Share
                  {shareIBANloading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                    <span class="sr-only">Loading...</span>
                  </div>}
                </button>
                {/* <button disabled={shareIBANloading} onClick={() => { shareIBAN(ibans[0]?.iban_id) }} type="button" class="btn btn-success btn-sm">Share</button> */}
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* /Modal Create IBAN*/}
      {/*row*/}
      {/* User plan Modal */}
      <div class="modal fade" id="userPlan">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">User Plan</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal">
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex justify-content-between">
                <span >Plane Name</span>
                <span >{userPlan?.plan_name}</span>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <span >No Plastic Card</span>
                <span >{userPlan?.no_plastic_card}</span>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <span >No VC</span>
                <span >{userPlan?.no_vc}</span>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <span >No VPC</span>
                <span >{userPlan?.no_vpc}</span>
              </div>
              <hr />
            </div>

          </div>
        </div>
      </div>
      {/* user plan Modal */}

      {/* dabit card */}

      <div class="modal fade" id="debitCard" style={{ zIndex: '5000' }}>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Debit Card</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal">
              </button>
            </div>
            <div class="modal-body">

              {/* <div >
                <div className="card  wallet" style={{ minHeight: '200px' }}>
                  <div className="boxs">
                    <span className="box one" />
                    <span className="box two" />
                    <span className="box three" />
                    <span className="box four" />
                  </div>
                  <div className="card-header border-0 pe-5">

               
                  </div>
                  <div className="card-body py-3  d-flex align-items-center justify-content-between flex-wrap">
                    <div className="wallet-info mt-auto">
                      <span className="fs-18 d-block">Wallet Balance</span>
                      <h4 className="font-w600 mb-0 d-inline-flex me-2 text-break d-flex align-items-center">
                        444
                      </h4>
                     
                    </div>
                    <div className="wallet-icon  mt-auto">
                      <svg
                        width={62}
                        height={39}
                        viewBox="0 0 62 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="42.7722"
                          cy="19.2278"
                          r="19.2278"
                          fill="white"
                          fillOpacity="0.2"
                        />
                        <circle
                          cx="19.2278"
                          cy="19.2278"
                          r="19.2278"
                          fill="white"
                          fillOpacity="0.2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div> */}

              {debitCards?.length > 0 && <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                <div class="carousel-inner">
                  {
                    debitCards?.length > 0 ? debitCards.map((card, index) => {
                      return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        {/* <div className="card  wallet" style={{ minHeight: '200px' }}>
                          <div className="boxs">
                            <span className="box one" />
                            <span className="box two" />
                            <span className="box three" />
                            <span className="box four" />
                          </div>
                          <div className="card-header border-0 pe-5">


                          </div>
                          <div className="card-body py-3  d-flex align-items-center justify-content-between flex-wrap">
                            <div className="wallet-info mt-auto">
                              <span className="fs-18 d-block">Wallet Balance</span>
                              <h4 className="font-w600 mb-0 d-inline-flex me-2 text-break d-flex align-items-center">
                                444
                              </h4>

                            </div>
                            <div className="wallet-icon  mt-auto">
                              <svg
                                width={62}
                                height={39}
                                viewBox="0 0 62 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="42.7722"
                                  cy="19.2278"
                                  r="19.2278"
                                  fill="white"
                                  fillOpacity="0.2"
                                />
                                <circle
                                  cx="19.2278"
                                  cy="19.2278"
                                  r="19.2278"
                                  fill="white"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </div>
                          </div>
                        </div> */}
                        <div style={{
                          position: 'relative', maxHeight: '220px', maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto',
                          '@media (max-width: 576px)': {
                            maxWidth: '230px',
                          },
                        }}>
                          <img style={{ maxHeight: '220px', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }} src={card?.card_image} alt="" />
                          <h4 style={{ position: 'absolute', top: 10, left: 15 }}>Debit Card</h4>
                          <div style={{ position: 'absolute', bottom: 10, left: 15 }}>
                            <span>XXXX XXXX XXXX {card?.card_number}</span><br />
                            <span>{card?.expiry_date}</span>
                          </div>

                        </div>
                        <div style={{
                          maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', '@media (max-width: 576px)': {
                            maxWidth: '230px',
                          },
                        }}>
                          <div className="d-flex justify-content-between mt-4">
                            <bold>Spent today</bold>
                            <bold>Spent Last 30 Days</bold>
                          </div>
                          <div className="d-flex justify-content-between ">
                            <div className="d-flex align-items-center">
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
                                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                              </svg> */}
                              <span>{card?.todayspent}</span>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
                                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                              </svg>  */}
                              <span>{card?.last_30_days}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-4">
                            <bold>Limit to spend</bold>
                            <bold>Available Month</bold>
                          </div>
                          <div className="d-flex justify-content-between ">
                            <div className="d-flex align-items-center">
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
                                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                              </svg>  */}
                              <span>{card?.limit_spent}</span>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
                                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                              </svg>  */}
                              <span>{card?.available}</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    }) : `you don't have any card `
                  }

                  {/* <div class="carousel-item">
                    <div className="card  wallet" style={{ minHeight: '200px' }}>
                      <div className="boxs">
                        <span className="box one" />
                        <span className="box two" />
                        <span className="box three" />
                        <span className="box four" />
                      </div>
                      <div className="card-header border-0 pe-5">


                      </div>
                      <div className="card-body py-3  d-flex align-items-center justify-content-between flex-wrap">
                        <div className="wallet-info mt-auto">
                          <span className="fs-18 d-block">Wallet Balance</span>
                          <h4 className="font-w600 mb-0 d-inline-flex me-2 text-break d-flex align-items-center">
                            444
                          </h4>

                        </div>
                        <div className="wallet-icon  mt-auto">
                          <svg
                            width={62}
                            height={39}
                            viewBox="0 0 62 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="42.7722"
                              cy="19.2278"
                              r="19.2278"
                              fill="white"
                              fillOpacity="0.2"
                            />
                            <circle
                              cx="19.2278"
                              cy="19.2278"
                              r="19.2278"
                              fill="white"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className="card  wallet" style={{ minHeight: '200px' }}>
                      <div className="boxs">
                        <span className="box one" />
                        <span className="box two" />
                        <span className="box three" />
                        <span className="box four" />
                      </div>
                      <div className="card-header border-0 pe-5">


                      </div>
                      <div className="card-body py-3  d-flex align-items-center justify-content-between flex-wrap">
                        <div className="wallet-info mt-auto">
                          <span className="fs-18 d-block">Wallet Balance</span>
                          <h4 className="font-w600 mb-0 d-inline-flex me-2 text-break d-flex align-items-center">
                            444
                          </h4>

                        </div>
                        <div className="wallet-icon  mt-auto">
                          <svg
                            width={62}
                            height={39}
                            viewBox="0 0 62 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="42.7722"
                              cy="19.2278"
                              r="19.2278"
                              fill="white"
                              fillOpacity="0.2"
                            />
                            <circle
                              cx="19.2278"
                              cy="19.2278"
                              r="19.2278"
                              fill="white"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {debitCards.length > 1 && <> <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button></>}
              </div>}
              {debitCards?.length <= 0 && <div>
                you don't have any cards
              </div>}
            </div>

          </div>
        </div>
      </div>

      {/* debit card */}

      {/* send Modal */}
      <Modal
                visible={isSendModalVisible}
                onCancel={sendmoneyModal}
                footer={[
                    <div className="d-flex justify-content-end align-items-center gap-2">
                       
                    </div>
                ]}
                wrapClassName="custom-ant-modal"
                closable={false}
                maskClosable={false}
            >
                
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Send To {selectedBeneficiary?.name}</h5>
                            <button type="button" class="btn-close" onClick={sendmoneyModal}>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3 ">
                                <label class="form-label">Payment Amount <img
													// src={ben?.image}
													src={defaultBencurrency ? defaultBencurrency : require("../../../assets/user-default-image.png")}
													className="rounded me-2"
													width={20}
													height={20}
													alt={defaultBencurrency}
												/>{" "}
												</label>
                                <input value={inputs?.amount || ''}
                                    onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, amount: e.target.value }))}
                                    type="number" class="form-control" placeholder="Enter Amount" />
                                {errors.amount && (
                                    <span className="text-danger">{errors.amount}</span>
                                )}
                            </div>
                            <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Select IBAN</label>
                                    <select ref={selectIbanRef} class="default-placeholder form-control " style={{background: '#371f9b',color: '#fff'}} placeholder="Select IBAN">
                                        <option disabled selected value={""}>Select IBAN</option>
                                        {sendmoneyIbans?.length > 0 && sendmoneyIbans.map((iban) => {
                                            return <option value={iban.iban_id}>{iban.label} ({iban.balance} {iban.currency})</option>
                                        })}
                                    </select>
                                    {errors?.iban_id && (
                                        <span className="text-danger">{errors?.iban_id}</span>
                                    )}
                                </div>
                            </div>
							
							{accountType == 'sepa' &&
                            <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Payment Option</label>
                                    <select ref={accountTypeRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select IBAN">
                                        <option disabled selected value={""}>Select Payment Option</option>
                                        <option value={"sepa"}>Sepa</option>
                                        <option value={"instant"}>Sepa Instant</option>
                                    </select>
                                    {errors.payment_option && (
                                        <span className="text-danger">{errors.payment_option}</span>
                                    )}
                                </div>
                            </div>
							}
                            <div class="mb-3 ">
                                <label class="form-label">Payment Reference</label>
                                <input  value={inputs?.reason_payment || ''}
                                    onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, reason_payment: e.target.value }))}
                                    type="text" class="form-control" placeholder="Reference" />
                                {errors.reason_payment && (
                                    <span className="text-danger">{errors.reason_payment}</span>
                                )}
                            </div>
							{shared == true &&
							
							 <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Shared Cost</label>
                                    <select ref={issharedRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select Shared Cost">
                                        <option value={"0"}>No</option>
                                        <option value={"1"}>Yes</option>
                                    </select>
                                </div>
                            </div>
							}
							
							{showpaymentoption == true &&
							 <div class="row">
                                <div class="mb-3 ">
                                    <label class="form-label">Select Purpose of Payment</label>
                                    <select ref={purposepaymentRef} class="default-placeholder form-control" style={{background: '#371f9b',color: '#fff'}} placeholder="Select Purpose of Payment">                                       
                                        {paymentoptions?.length > 0 && paymentoptions.map((k) => {
                                            return <option value={k.payment_code}>{k.payment_description}</option>
                                        })}
                                    </select>                                   
                                </div>
                            </div>
							}
                            <div className="d-flex justify-content-end">
                                {/* <button onClick={sendMoney} type="button" class="btn btn-success btn-sm">Send Now</button> */}

                                <button disabled={loading} type="button" onClick={sendMoney} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                    Send Now
                                    {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>
      {/* send Modal */}
      <Modal
        visible={isOtpModalVisible}
        onCancel={hideOtpModal}
        footer={[
          <div className="d-flex justify-content-end align-items-center gap-2">
            <button type="button" onClick={hideOtpModal} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
              Cancel

            </button>

            <button disabled={confirmOTPLoading} type="button" onClick={confirmOTP} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
              Confirm
              {confirmOTPLoading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                <span class="sr-only">Loading...</span>
              </div>}
            </button>
          </div>
        ]}
        wrapClassName="custom-ant-modal"
        closable={false}
        maskClosable={false}
      >
        <div className="text-white">
          <h3>Confirm OTP</h3>
          <span>An OTP is sent to your email . Please Enter OTP below to confirm transfer.</span>
        </div>
        <input value={OTP || ''}
          onChange={(e) => setOTP(e.target.value)}
          type="number" class="form-control" placeholder="Enter OTP" />

      </Modal>
    </div>

  )
}
export default Dashboard


